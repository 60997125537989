import { AfterContentInit, AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from "@angular/core";

export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  standalone: false,
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class SortableHeader implements AfterContentInit {
  @Input() sortable: string = '';
  @Input() init: boolean = false;
  @Input() direction: SortDirection = '';
  @Output() sort: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();

  constructor(
    private elRef: ElementRef,
  ) { }

  ngAfterContentInit(): void {
    if (this.init) {
      this.rotate();
    }
  }

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction } as SortEvent);

    // Get all th elements
    const headers = this.elRef.nativeElement.parentElement.children;
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].nodeName === 'TH') {
        // Remove class from all th elements
        headers[i].classList.remove('asc');
        headers[i].classList.remove('desc');

        // Hide all fa-icon child elements
        const children = headers[i].children;
        for (let j = 0; j < children.length; j++) {
          if (children[j].nodeName === 'FA-ICON') {
            // Show if class is direction
            if (headers[i].getAttribute('sortable') == this.sortable) {
              if (children[j].classList.contains(this.direction) || (!this.direction && !children[j].classList.contains('asc') && !children[j].classList.contains('desc'))) {
                children[j].style.display = 'inline';
              } else {
                children[j].style.display = 'none';
              }
            } else {
              if (children[j].classList.contains('asc') || children[j].classList.contains('desc')) {
                children[j].style.display = 'none';
              } else {
                children[j].style.display = 'inline';
              }
            }
          }
        }
      }
    }


  }
}