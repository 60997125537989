import { Injectable } from '@angular/core';
import { Customer } from '@models/customer';
import { AuthService } from './auth.service';
import { ErrorService } from './error.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  public getCustomers(): Promise<Customer[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/customers/getAll`).then((response) => {
        return resolve(response);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject();
      });
    });
  }

  public getCustomer(id: string | null): Promise<Customer> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/customers/get`, {
        partyId: id,
      }).then((response: Customer) => {
        return resolve(response);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject();
      });
    });
  }

  public createCustomer(
    name: string,
    customerNo?: string,
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/customers/create`, {
        customerNo,
        name,
      }).then((result: any) => {
        return resolve(result.partyId);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public getCustomerEmployees(partyId: string): Promise<string[]> {
    let employees: string[] = [];
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/customers/getEmployees`, { partyId }).then((result: string[]) => {
        if (result) {
          for (const employee of result) {
            employees.push(employee);
          }
        }
        return this.authService.refreshUser();
      }).then(() => {
        return resolve(employees);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

}
