import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { ROLES } from '@services/constants.service';

import { AuthGuard } from '@app/guards/auth.guard';
import { ContainerComponent } from '@app/pages/container/container.component';
import { LoginComponent } from '@app/extras/login/login.component';
import { ImprintComponent } from '@app/extras/imprint/imprint.component';
import { PrivacyComponent } from '@app/extras/privacy/privacy.component';
import { MainComponent } from '@app/common/main/main.component';
import { AdminMainComponent } from '@app/pages/admin/main/main.component';
import { SettingsComponent } from '@app/common/settings/settings.component';
import { CustomersComponent } from '@app/pages/admin/customers/customers.component';
import { CustomerDetailComponent } from './pages/admin/customers/customer-detail/customer-detail.component';
import { SuppliersComponent } from './pages/admin/suppliers/suppliers.component';
import { SupplierDetailComponent } from './pages/admin/suppliers/supplier-detail/supplier-detail.component';
import { DataStreamsComponent } from './pages/admin/data/data-streams/data-streams.component';
import { MediaSearchComponent } from './pages/admin/data/media-search/media-search.component';
import { JobsComponent } from './pages/admin/jobs/jobs.component';
import { DataCatalogsComponent } from './pages/admin/data/data-catalogs/data-catalogs.component';
import { DataCatalogDetailComponent } from './pages/admin/data/data-catalogs/data-catalog-detail/data-catalog-detail.component';
import { SupplierProductDetailComponent } from './pages/admin/data/data-catalogs/data-catalog-detail/supplier-products-overview/supplier-product-detail/supplier-product-detail.component';
import { SupplierProductSearchComponent } from './pages/admin/data/supplier-product-search/supplier-product-search.component';
import { RecoverPasswordComponent } from './extras/recover-password/recover-password.component';
import { CockpitComponent as SupplierCockpitComponent } from './pages/supplier/cockpit/cockpit.component';
import { CockpitComponent as CustomerCockpitComponent } from './pages/customer/cockpit/cockpit.component';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'imprint', component: ImprintComponent },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'recover-password', component: RecoverPasswordComponent },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'app',
    component: ContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'common',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'main',
            component: MainComponent
          },
          {
            path: 'settings',
            data: {
              roles: [ROLES.CUSTOMER, ROLES.SUPPLIER, ROLES.ADMIN],
            },
            component: SettingsComponent,
          },
          {
            path: '',
            redirectTo: '/app/common/main',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'customers',
        canActivate: [AuthGuard],
        data: {
          roles: [ROLES.CUSTOMER],
        },
        children: [
          {
            path: 'main',
            component: CustomerCockpitComponent
          },
          {
            path: '',
            redirectTo: '/app/customers/main',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'suppliers',
        canActivate: [AuthGuard],
        data: {
          roles: [ROLES.SUPPLIER]
        },
        children: [
          {
            path: 'main',
            component: SupplierCockpitComponent
          },
          {
            path: '',
            redirectTo: '/app/suppliers/main',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: {
          roles: [ROLES.ADMIN],
        },
        children: [
          {
            path: 'main',
            component: AdminMainComponent
          },
          {
            path: 'customers',
            component: CustomersComponent,
          },
          {
            path: 'customers/:id',
            component: CustomerDetailComponent,
          },
          {
            path: 'suppliers',
            component: SuppliersComponent,
          },
          {
            path: 'suppliers/:id',
            component: SupplierDetailComponent,
          },
          {
            path: 'data-streams',
            component: DataStreamsComponent
          },
          {
            path: 'media-search',
            component: MediaSearchComponent
          },
          {
            path: 'jobs',
            component: JobsComponent
          },
          {
            path: 'data-catalogs',
            component: DataCatalogsComponent
          },
          {
            path: 'data-catalogs/:id',
            component: DataCatalogDetailComponent
          },
          {
            path: 'supplier-product/:id',
            component: SupplierProductDetailComponent
          },
          {
            path: 'supplier-product-search',
            component: SupplierProductSearchComponent
          },
          {
            path: '',
            redirectTo: '/app/admin/main',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: '',
        redirectTo: '/app/common/main',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: '',
    redirectTo: '/app/common/main',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/app/common/main' }
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
