import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ErrorService } from '@services/error.service';

import { Supplier } from '@models/supplier';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@envs/environment.production';
import { ROLES } from '@services/constants.service';
import { SupplierService } from '@services/supplier.service';
import { DataStream } from '@models/data-stream';
import { DataStreamService } from '@services/data-stream.service';
import { DataCatalogService } from '@services/data-catalog.service';
import { SortableHeader, SortDirection } from '@app/directives/sortable-header';

const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

  public loading: boolean = false;
  public loadingCreate: boolean = false;
  public loadingContinue: boolean = false;
  public createStep: number = 1;

  public suppliers: Supplier[] = [];
  public dataStreams: DataStream[] = [];
  public dataCatalogs: any[] = [];

  public supplierForm: FormGroup;
  public userProps = { employerPartyId: "", employeeRoles: [ROLES.SUPPLIER] };

  private supplierCreateModal: NgbModalRef | null = null;
  public error: string = '';

  public readonly FINISH: string = "finish";
  public readonly CONTINUE: string = "continue";

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private supplierService: SupplierService,
    private errorService: ErrorService,
    private translateService: TranslateService,
    private dataStreamService: DataStreamService,
    private dataCatalogService: DataCatalogService,
  ) {
    if (!environment.production) {
      this.supplierForm = this.formBuilder.group({
        gln: ['400234400209',
          Validators.compose([])
        ],
        companyName: ['Test-Firma',
          Validators.compose([Validators.required])
        ],
      });
    } else {
      this.supplierForm = this.formBuilder.group({
        gln: ['',
          Validators.compose([])
        ],
        companyName: ['',
          Validators.compose([Validators.required])
        ],
      });
    }
  }

  ngOnInit(): void {
    this.loadSuppliers();
  }

  public loadSuppliers(): void {
    this.loading = true;

    this.supplierService.getSuppliers().then((suppliers: any) => {
      this.suppliers = suppliers;
      this.onSort({ column: 'name', direction: 'asc' });
      this.loading = false;

      this.dataStreamService.getDataStreams().then((dataStreams: DataStream[]) => {
        this.dataStreams = dataStreams;
        this.suppliers.forEach((supplier: Supplier) => {
          dataStreams.forEach((dataStream: DataStream) => {
            // Check if the supplier has a data stream
            if (supplier.partyId === dataStream.supplier?.partyId) {
              supplier.dataStream = dataStream;
            }
          });
        });
      }).catch((error: any) => {
        this.errorService.printError(error);
      });

      this.dataCatalogService.getDataCatalogs().then((dataCatalogs: any[]) => {
        this.dataCatalogs = dataCatalogs;
        this.suppliers.forEach((supplier: Supplier) => {
          dataCatalogs.forEach((dataCatalog: any) => {
            // Check if the supplier has a data stream
            if (supplier.partyId === dataCatalog.supplierPartyId) {
              supplier.dataCatalog = dataCatalog;
            }
          });
        });
      }).catch((error: any) => {
        this.errorService.printError(error);
      });
    }).catch((error: any) => {
      this.loading = false;
      this.errorService.printError(error);
    });
  }



  public createSupplier(): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.supplierService.createSupplier(
        this.supplierForm.controls['companyName'].value,
        this.supplierForm.controls['gln'].value,
      ).then((respEmployerPartyId: string) => {
        if (respEmployerPartyId) {
          this.userProps.employerPartyId = respEmployerPartyId;
          this.supplierForm.reset();
          this.error = '';
        }

        this.loadSuppliers();
        return resolve();
      }).catch((error: string) => {
        this.error = error || this.translateService.instant("Dialog.SuppliersComponent.msg_supplierNotCreated");

        return reject(error);
      });
    })
  }

  public openModal(modal: any) {
    this.supplierCreateModal = this.modalService.open(modal);
    this.supplierCreateModal.result.then(() => {
      this.resetSupplierForm();
    }, () => {
      this.resetSupplierForm();
    });
  }

  private resetSupplierForm() {
    this.supplierForm.reset();
    this.loadingCreate = false;
    this.error = '';
    this.createStep = 1;
  }

  public exitUserForm(event: boolean) {
    this.modalService.dismissAll();
    if (event == true) {
      this.router.navigate(['/app', 'admin', 'suppliers', this.userProps.employerPartyId]);
    }
  }

  public onSubmit(event: any) {
    if (event.submitter.name == this.CONTINUE) {
      // if button with name 'continue' was pressed
      this.loadingContinue = true;
      this.createSupplier().then(() => {
        this.loadingContinue = false;
        // go to the second step (creation of contact user)
        this.createStep = 2;
      }).catch((err) => {
        this.errorService.printError(err);
        this.loadingContinue = false;
      })
    } else if (event.submitter.name == this.FINISH) {
      // if button with name 'finish' was pressed
      this.loadingCreate = true;
      this.createSupplier().then(() => {
        this.loadingCreate = false;
        // dismiss the modal directly on success
        this.supplierCreateModal?.dismiss();
      }).catch((err) => {
        this.errorService.printError(err);
        this.loadingCreate = false;
      })
    }
  }

  @ViewChildren(SortableHeader) headers!: QueryList<SortableHeader>;
  public onSort(event: any) {
    let column = event.column as string;
    let direction = event.direction as SortDirection;

    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      direction = 'asc';
      column = 'name';
    }

    if (column == 'name') {
      this.suppliers = [...this.suppliers].sort((a: any, b: any) => {
        const res = compare(a.attributes?.['NAME']?.toLowerCase(), b.attributes?.['NAME']?.toLowerCase());
        return direction === 'asc' ? res : -res;
      });
    } else if (column == 'gln') {
      this.suppliers = [...this.suppliers].sort((a: any, b: any) => {
        const glnA = a?.identifications?.find((identification: any) => identification.type === 'GLN');
        const glnB = b?.identifications?.find((identification: any) => identification.type === 'GLN');
        const res = compare(glnA, glnB);
        return direction === 'asc' ? res : -res;
      });
    } else if (column == 'enabled') {
      this.suppliers = [...this.suppliers].sort((a: any, b: any) => {
        const res = (!!a.enabled === !!b.enabled) ? 0 : !!a.enabled ? -1 : 1;
        return direction === 'asc' ? res : -res;
      });
    } else if (column == 'dataCatalog') {
      this.suppliers = [...this.suppliers].sort((a: any, b: any) => {
        const res = (!!a.dataCatalog === !!b.dataCatalog) ? 0 : !!a.dataCatalog ? -1 : 1;
        return direction === 'asc' ? res : -res;
      });
    } else if (column == 'dataStream') {
      this.suppliers = [...this.suppliers].sort((a: any, b: any) => {
        const res = (!!a.dataStream === !!b.dataStream) ? 0 : !!a.dataStream ? -1 : 1;
        return direction === 'asc' ? res : -res;
      });
    } else {
      this.suppliers = [...this.suppliers].sort((a: any, b: any) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

}
