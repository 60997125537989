import { Injectable } from '@angular/core';
import { Party } from '@models/party';
import { PartyRelationship } from '@models/party-relationship';
import { AuthService } from './auth.service';
import { ErrorService } from './error.service';
import { HttpService } from './http.service';
import { DataCatalogStatus } from '@models/data-catalog-status';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PartyService {
  public getRootParty(): Promise<Party | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/parties/get-root-party").then((response: Party | null) => {
        return resolve(response);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private errorService: ErrorService,
  ) { }

  public getPartyRelationships(partyId: string): Promise<PartyRelationship[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/parties/getRelationships`, { partyId }).then((result: any) => {
        if (result) {
          return resolve(result);
        }
        return resolve([]);
      }).catch(() => {
        return reject();
      })
    })
  }

  public updatePartyName(
    name: string,
    partyId?: string,
  ): Promise<void> {
    return this.updateParty({
      name,
      partyId,
    });
  }

  public updateParty(fields: any): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/customers/update`, fields).then((result: any) => {
        return this.authService.refreshUser();
      }).then(() => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public postFile(file: File, partyId: string): Promise<string | null> {
    if (!file || !partyId) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', file);
      formData.append('partyId', partyId);

      return this.httpService.post(`**/supplier/uploadDataCatalog`, formData, { fullResponse: true }).then(() => {
        return resolve(null);
      }).catch((error: HttpErrorResponse) => {
        console.error('postFile error:', error);
        this.errorService.printError(error);
        return reject(error);
      });
    });
  }

  public getDataCatalogStatus(partyId: string): Promise<DataCatalogStatus | null> {
    if (!partyId) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/supplier/getDataCatalogStatus`, { partyId }).then((dataCatalogStatus: DataCatalogStatus) => {
        return resolve(dataCatalogStatus);
      }).catch((error: any) => {
        this.errorService.printError(error);
        return reject(error);
      });
    });
  }

  public getAttribute(partyId: string, attributeType: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/parties/get-attribute`, { partyId: partyId, attributeTypeId: attributeType }).then((resp: string | null) => {
        return resolve(resp);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  public setAttribute(partyId: string, attributeType: string, attributeValue: string): Promise<Party> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/parties/set-attribute`, {
        partyId: partyId,
        attributeTypeId: attributeType,
        attributeValue: attributeValue
      }).then((resp: Party | null) => {
        if (!resp) {
          return reject();
        }

        return resolve(resp);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  public removePartyRelationship(fromPartyId: string, toPartyId: string, relationshipType: string): Promise<void> {
    if (!fromPartyId || !toPartyId || !relationshipType) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/parties/delete-relationship`, {
        fromPartyId: fromPartyId,
        toPartyId: toPartyId,
        typeId: relationshipType,
      }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public createPartyRelationship(fromPartyId: string, toPartyId: string, relationshipType: string): Promise<void> {
    if (!fromPartyId || !toPartyId || !relationshipType) {
      return Promise.reject("'fromPartyId' or 'toPartyId' undefined.");
    }

    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/parties/create-relationship`, {
        fromPartyId: fromPartyId,
        toPartyId: toPartyId,
        typeId: relationshipType
      }).then(() => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    })
  }

  public getParty(partyId: string, withRelationships?: boolean): Promise<Party | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/parties/getParty", { 
        partyId: partyId,
        withRelationships: !!withRelationships,
       }).then((response: Party | null) => {
        return resolve(response);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  public getViewType(partyId: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/parties/get-view-type", { partyId: partyId }).then((response: any | null) => {
        return resolve(response?.type!);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

}
