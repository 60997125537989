<div class="pb-0 ps-1 pe-1 pt-1 border rounded datastream" [formGroup]="parentFormGroup">
	<div class="form-check" *ngIf="parentFormGroup" formArrayName="dataStreamIds">
		<input class="form-check-input" type="checkbox" [id]="'tile' + dataStream.id" [value]="dataStream.id" (change)="dataStreamIdChange($event)" #inputControl />

		<div class="tile-label" *ngIf="viewSetting == 'TILE'">
			<label class="form-check-label d-block" [for]="'tile' + dataStream.id">
				<div class="row">
					<div [ngClass]="{ 'col-7 pe-0': dataStream.logoImageUrl, col: !dataStream.logoImageUrl }">
						<strong class="d-block fw-semibold">{{ dataStream.supplier?.attributes?.['NAME'] }}</strong>
						<small *ngIf="dataStream.supplier! | identification : 'GLN'" class="d-block"><strong>GLN:</strong> {{ dataStream.supplier! | identification : "GLN" }}</small>
						<small class="d-block"><strong>Produkte:</strong> {{ dataStream.productCount || 0 | number }}</small>
						<small class="d-block" *ngIf="dataStream.versionDate"><strong>Version:</strong> {{ dataStream.versionDate | date }}</small>
					</div>

					<div class="col-5 logo" *ngIf="dataStream.logoImageUrl">
						<img class="img-fluid" [src]="dataStream.logoImageUrl" />
					</div>

					<button (click)="showContact()" class="btn-link float-right" *ngIf="dataStream.contactData">
						<fa-icon icon="comment"></fa-icon>
					</button>
				</div>
			</label>

			<small class="d-block fw-bold text-danger text-uppercase" *ngIf="dataStream.firstImportDate! < newMinDate">Neu</small>
		</div>
		<div class="list-label row" *ngIf="viewSetting == 'LIST'">
			<label class="col-4" [for]="'tile' + dataStream.id">
				<strong class="d-block">{{ dataStream.supplier?.attributes?.['NAME'] }}</strong>
				<small *ngIf="dataStream.contactData" class="d-block">
					<button (click)="showContact()" class="btn-link">
						<fa-icon icon="comment"></fa-icon>
					</button>
				</small>
			</label>
			<label class="col-4 mb-0" [for]="'tile' + dataStream.id">
				<small *ngIf="dataStream.supplier! | identification : 'GLN'" class="d-block"><strong>GLN:</strong> {{ dataStream.supplier! | identification : "GLN" }}</small>
				<small class="d-block"><strong>Produkte:</strong> {{ dataStream.productCount || 0 | number }}</small>
				<small class="d-block" *ngIf="dataStream.versionDate"><strong>Version:</strong> {{ dataStream.versionDate | date }}</small>
			</label>
			<div class="col-1">
				<span class="d-block fw-bold text-danger text-uppercase" *ngIf="dataStream.firstImportDate! < newMinDate">Neu</span>
			</div>
		</div>
	</div>
</div>

<ng-template #contactModal let-modal>
	<div class="modal-header">
		<h5 class="modal-title">Ansprechpartner für Daten</h5>
		<button type="button" class="btn-close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body pb-0">
		<p>Bei etwaigen Rückfragen können Sie sich jederzeit an den Lieferanten wenden:</p>
		<div *ngIf="dataStream.contactData">
			<strong class="d-block">Ansprechpartner::</strong>
			<div>
				{{ dataStream.contactData.firstName }}
				{{ dataStream.contactData.lastName }}
			</div>

			<div>
				E-Mail: <a href="mailto:{{ dataStream.contactData.email }}">{{ dataStream.contactData.email }}</a>
			</div>

			<div>Telefon: {{ dataStream.contactData.phone }}</div>
		</div>
	</div>
</ng-template>
