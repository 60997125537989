import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AdminService } from '@services/admin.service';
import { DialogService } from '@services/dialog.service';

import { Supplier } from '@models/supplier';
import { TranslateService } from '@ngx-translate/core';
import { PartyService } from '@services/party.service';
import { SupplierService } from '@services/supplier.service';
import { DataStream } from '@models/data-stream';
import { DataStreamService } from '@services/data-stream.service';

@Component({
  selector: 'app-supplier-detail',
  templateUrl: './supplier-detail.component.html',
  styleUrls: ['./supplier-detail.component.scss']
})
export class SupplierDetailComponent implements OnInit {

  public id!: string | null;
  public supplier!: Supplier | null;
  public dataStreams: DataStream[] | null = [];
  public loading: any = {
    'supplier': true,
  };
  public defaultFormValues: any = {};

  constructor(
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private adminService: AdminService,
    private supplierService: SupplierService,
    private partyService: PartyService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private dataStreamService: DataStreamService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.reload();
  }

  public reload(): void {
    this.loading = true;

    this.supplierService.getSupplier(this.id).then((supplier: Supplier) => {
      this.supplier = supplier;
      this.defaultFormValues = supplier;

      return this.dataStreamService.getDataStreamsByParty(this.id || '');
    }).then((dataStreams: DataStream[] | null) => {
      this.dataStreams = dataStreams;

      this.loading = false;
    }).catch(() => {
      this.loading = false;
      this.dialogService.alert(null, this.translateService.instant("Dialog.SupplierDetailComponent.msg_supplierNotLoaded"), 'danger', 0, false);
    });
  }

  public async toggleSupplier(enable: boolean) {
    let title: string;
    let body: string;
    let messageSuccess: string;
    let messageFailure: string;
    if (enable) {
      title = this.translateService.instant("Dialog.SupplierDetailComponent.text_enableSupplier");
      body = this.translateService.instant("Dialog.SupplierDetailComponent.text_confirmEnableSupplier");
      messageSuccess = this.translateService.instant("Dialog.SupplierDetailComponent.msg_supplierEnabled");
      messageFailure = this.translateService.instant("Dialog.SupplierDetailComponent.msg_supplierNotEnabled");
    } else {
      title = this.translateService.instant("Dialog.SupplierDetailComponent.text_disableSupplier");
      body = this.translateService.instant("Dialog.SupplierDetailComponent.text_confirmDisableSupplier");
      messageSuccess = this.translateService.instant("Dialog.SupplierDetailComponent.msg_supplierDisabled");
      messageFailure = this.translateService.instant("Dialog.SupplierDetailComponent.msg_supplierNotEnabled");
    }

    if (! await this.dialogService.confirm(
      title,
      body,
      this.viewContainerRef
    )) {
      return;
    }

    this.dialogService.showLoader('supplier-detail.toggleSupplier');

    this.adminService.updatePartyEnabled(enable, this.supplier?.partyId).then(() => {
      this.dialogService.alert(null, messageSuccess, 'success');
      this.reload();

      this.dialogService.dismissLoader('supplier-detail.toggleSupplier');
    }).catch((error: string) => {
      this.dialogService.alert(null, error || messageFailure, 'danger');

      this.dialogService.dismissLoader('supplier-detail.toggleSupplier');
    });
  }

  public changeSupplierName() {
    this.dialogService.prompt(
      this.translateService.instant("Dialog.SupplierDetailComponent.text_editCompanyName"),
      this.translateService.instant("Dialog.SupplierDetailComponent.text_enterNewCompanyName"),
      'text',
      this.supplier?.attributes?.['NAME'] || '',
      this.viewContainerRef,
      this.translateService.instant("Dialog.SupplierDetailComponent.text_companyName")
    ).then((input: string | number | null) => {
      if (typeof input == 'string') {
        this.dialogService.showLoader('supplier-detail.changeSupplierName');

        return this.partyService.updatePartyName(input, this.supplier?.partyId);
      }

      return Promise.reject();
    }).then(() => {
      this.dialogService.alert(
        null,
        this.translateService.instant("Dialog.SupplierDetailComponent.text_companyNameEditedSuccessfully"),
        'success'
      );

      this.reload();
      this.dialogService.dismissLoader('supplier-detail.changeSupplierName');
    }).catch(() => {
      this.dialogService.alert(
        null,
        this.translateService.instant("Dialog.SupplierDetailComponent.text_companyNameNotEditedSuccessfully"),
        'danger'
      );

      this.dialogService.dismissLoader('supplier-detail.changeSupplierName');
    });
  }

  public changeSupplierLogo() {
    this.dialogService.unimplementedWarning();
  }

}