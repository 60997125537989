<div class="row">
	<div class="col-6">
		<h3>{{ "Template.DatastreamsComponent.subHeader_manageDatastreams" | translate }}</h3>
	</div>
	<div class="col-6 text-end">
		<a class="btn btn-secondary float-end" (click)="getDataStreamAnalysis()">
			<i class="fas fa-cloud-download-alt"></i>
			{{ "Template.DatastreamsComponent.btn_datastreamEvaluation" | translate }}
		</a>
	</div>
</div>

<div *ngIf="loading" class="text-center p-5">
	<fa-icon icon="spinner" class="fa-spin" size="3x" animation="spin"></fa-icon>
</div>

<table *ngIf="dataStreams?.length && !loading" class="table table-striped table-hover mt-3">
	<thead>
		<tr>
			<th width="30%" scope="col" sortable="name" [init]="true" (sort)="onSort($event)">
				<fa-icon class="asc" icon="sort-up"></fa-icon>
				<fa-icon class="desc" icon="sort-down"></fa-icon>
				<fa-icon icon="sort"></fa-icon>
				{{ "Template.DatastreamsComponent.text_dataSupplier" | translate }}
			</th>
			<th width="15%" class="text-center" scope="col" sortable="versionDate" (sort)="onSort($event)">
				<fa-icon class="asc" icon="sort-up"></fa-icon>
				<fa-icon class="desc" icon="sort-down"></fa-icon>
				<fa-icon icon="sort"></fa-icon>
				{{ "Template.DatastreamsComponent.text_state" | translate }}
			</th>
			<th width="15%" class="text-center" scope="col" sortable="lastImportDate" (sort)="onSort($event)">
				<fa-icon class="asc" icon="sort-up"></fa-icon>
				<fa-icon class="desc" icon="sort-down"></fa-icon>
				<fa-icon icon="sort"></fa-icon>
				Letzter Import
			</th>
			<th width="15%" class="text-center" scope="col" sortable="lastExportDate" (sort)="onSort($event)">
				<fa-icon class="asc" icon="sort-up"></fa-icon>
				<fa-icon class="desc" icon="sort-down"></fa-icon>
				<fa-icon icon="sort"></fa-icon>
				Letzter Export
			</th>
			<th width="10%" class="text-center" scope="col" sortable="productCount" (sort)="onSort($event)">
				<fa-icon class="asc" icon="sort-up"></fa-icon>
				<fa-icon class="desc" icon="sort-down"></fa-icon>
				<fa-icon icon="sort"></fa-icon>
				{{ "Template.DatastreamsComponent.text_products" | translate }}
			</th>
			<th width="10%" class="text-center" scope="col">{{ "Template.DatastreamsComponent.text_logo" | translate }}</th>
			<th width="10%" class="text-center" scope="col">{{ "Template.DatastreamsComponent.text_retrievable" | translate }}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let dataStream of dataStreams">
			<input type="hidden" name="dataStreamIds[]" value="{{ dataStream.id }}" />
			<td class="align-middle">
				{{ dataStream.supplier?.attributes?.['NAME'] }}
				<small class="d-block mb-1">
					{{ dataStream.supplier! | identification : "GLN" }}
				</small>

				<a *ngIf="dataStream.supplierPartyId" [routerLink]="['/app', 'admin', 'suppliers', dataStream.supplierPartyId]" class="btn btn-link btn-sm">
					{{ "Template.DatastreamsComponent.btn_details" | translate }}
					<fa-icon icon="chevron-right"></fa-icon>
				</a>
			</td>
			<td class="align-middle text-center">
				<span *ngIf="dataStream.versionDate">
					{{ dataStream.versionDate | date : "dd.MM.yyyy" }}
				</span>
				<span *ngIf="!dataStream.versionDate">---</span>
			</td>
			<td class="align-middle text-center">
				<div *ngIf="dataStream.lastImportDate">
					<span class="d-block">{{ dataStream.lastImportDate | date : "dd.MM.yyyy" }}</span>
					<small class="d-block">
						{{ dataStream.lastImportDate | date : "HH:mm" }} Uhr
					</small>
				</div>
				<div *ngIf="!dataStream.lastImportDate">---</div>
			</td>
			<td class="align-middle text-center">
				<div *ngIf="dataStream.lastExportDate">
					<span class="d-block">{{ dataStream.lastExportDate | date : "dd.MM.yyyy" }}</span>
					<small class="d-block">
						{{ dataStream.lastExportDate | date : "HH:mm" }} Uhr
					</small>
				</div>
				<div *ngIf="!dataStream.lastExportDate">---</div>
			</td>
			<td class="align-middle text-center">
				{{ dataStream.productCount | number }}
			</td>
			<td class="align-middle text-center">
				<div class="logo-image">
					<button *ngIf="dataStream.logoImageUrl" type="button" class="btn btn-link" data-toggle="popover" data-trigger="hover" data-placement="top" data-html="true" data-content="todo: Logo-Content">
						<fa-icon icon="check" class="text-success"></fa-icon>
					</button>
					<button *ngIf="!dataStream.logoImageUrl" type="button" class="btn btn-link" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Kein Logo hochgeladen">
						<fa-icon icon="xmark" class="text-danger"></fa-icon>
					</button>
				</div>
			</td>
			<td class="align-middle text-center">
				<!-- <#if Static["org.ofbiz.ekintegration.data.EkDataWorker"].isDataStreamManagedByVendor(delegator, dataStream.dataStreamId)>-->
				<div *ngIf="dataStream.managedBySupplier">
					<!--  <#if dataStream.activeLogins?has_content && dataStream.activeLogins?size gt 0> -->
					<div *ngIf="dataStream.accesses">
						<!-- <#if dataStream.openAccess=="Y">
                                                <button *ngIf="dataStream.openAccess=='Y'" type="button" class="btn btn-link" data-toggle="popover" <i
                                                    class="fas fa-handshake text-success"></i>
                                                </button>
                                                <#else>
                                                    <button *ngIf="dataStream.openAccess!='Y'" type="button" class="btn btn-link" data-toggle="popover" <i
                                                        class="fas fa-handshake text-warning"></i>
                                                    </button>
                                            </#if> -->
					</div>
					<div *ngIf="!dataStream.accesses">
						<button type="button" class="btn btn-link" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Keine Zugänge vergeben oder aktiviert (ZR-Datenstream)">
							<fa-icon icon="times" class="text-danger"></fa-icon>
						</button>
					</div>
				</div>
				<div *ngIf="!dataStream.managedBySupplier">
					<button type="button" class="btn btn-link" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Daten-Stream ist frei abrufbar">
						<fa-icon icon="check" class="text-success"></fa-icon>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
	<caption>
		{{
			"Template.DatastreamsComponent.text_dataStreamsProductsDisabledProducts" | translate : { qtyDatastreams: dataStreams.length, qtyProducts: productCount | number }
		}}
	</caption>
</table>
