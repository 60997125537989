import { Injectable } from '@angular/core';
import { IDataStreamAccess } from '@app/interfaces/idata-stream-access';
import { DataStream } from '@models/data-stream';
import { DataStreamGroup } from '@models/data-stream-group';
import { Supplier } from '@models/supplier';
import { ErrorService } from './error.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DataStreamService {

  constructor(
    private httpService: HttpService,
    private errorService: ErrorService
  ) { }

  public getRequestableDataStreams(dataStreamGroupId: string, partyId: string): Promise<DataStream[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-streams/getRequestableByGroupId', {
        dataStreamGroupId: dataStreamGroupId,
        partyId: partyId,
      }).then((dataStreams: DataStream[]) => {
        return resolve(dataStreams);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      });
    })
  }

  public getDataStreams(): Promise<DataStream[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-streams/getAll').then((dataStreams: DataStream[]) => {
        return resolve(dataStreams);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public getDataStreamGroups(): Promise<DataStreamGroup[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-stream-groups/getAll').then((dataStreamGroups: DataStreamGroup[]) => {
        if (!dataStreamGroups) {
          return reject();
        }
        return resolve(dataStreamGroups);
      }).catch((error) => {
        this.errorService.printError(error);
        return reject();
      })
    })
  }

  public getDataStreamGroup(dataStreamGroupId: string): Promise<DataStreamGroup> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-stream-groups/get', {
        id: dataStreamGroupId
      }).then((dataStreamGroup: DataStreamGroup) => {
        if (!dataStreamGroup) {
          return reject();
        }

        return resolve(dataStreamGroup);
      }).catch((error) => {
        this.errorService.printError(error);
        return reject();
      })
    })
  }

  public getDataStreamsForDataStreamGroup(dataStreamGroupId: string, partyId: string, statusId?: string): Promise<DataStream[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-streams/getDataStreamsByGroupId', {
        dataStreamGroupId,
        partyId: partyId,
        statusId: statusId,
      }).then((dataStreams: DataStream[]) => {
        return resolve(dataStreams);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public getAccesses(dataStreamId: string): Promise<IDataStreamAccess[] | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/data-streams/getAccesses", { dataStreamId: dataStreamId }).then((dataStreamAccess: IDataStreamAccess[]) => {
        return resolve(dataStreamAccess);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public sendDataStreamAccessRequest(dataStreamId: string, partyId: string, personPartyId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post("**/data-streams/request-access", { dataStreamId, partyId, personPartyId }).then(() => {
        return resolve();
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public getDataStreamsByParty(partyId: string): Promise<DataStream[] | null> {
    return new Promise((resolve, reject) => {
      return this.httpService.get("**/data-streams/getDataStreamsByParty", { partyId: partyId }).then((dataStreams: DataStream[]) => {
        return resolve(dataStreams);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public getDataStreamGroupAccesses(employerPartyId: string | undefined): Promise<string[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get(`**/data-stream-groups/get-accesses`, {
        employerPartyId,
      }).then((result: any) => {
        return resolve(result);
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    });
  }

  public setDataStreamGroupAccesses(employerPartyId: string | undefined, dataStreamGroupIds: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/data-stream-groups/set-accesses`, {
        employerPartyId,
        dataStreamGroupIds,
      }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    });
  }

  public sendGenericDownloadRequest(dataStreamIds: string[], employerPartyId: string | undefined, format: string): void {
    this.httpService.openExternalUrl(`**/data-streams/generic-download`, {
      partyId: employerPartyId,
      dataStreamIds,
      format,
    });
  }

  public setDataStreamAccess(dataStreamId: string, customerPartyId: string, statusId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.httpService.post(`**/data-streams/setAccess`, {
        dataStreamId,
        customerPartyId,
        statusId,
      }).then((result: any) => {
        return resolve();
      }).catch((error: any) => {
        this.errorService.printError(error);

        return reject(error);
      })
    });
  }
}
