<div class="titlebar">
	<h3>{{ "Template.CockpitComponent.subHeader_supplierHomepage" | translate }}</h3>
</div>

<ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom-0 ps-2">
	<li ngbNavItem>
		<button ngbNavLink>Daten-Management</button>
		<ng-template ngbNavContent>
			<app-data-management *ngIf="currentPartyId" [partyId]="currentPartyId"></app-data-management>
		</ng-template>
	</li>
	<li ngbNavItem>
		<button ngbNavLink>Daten-Freigaben</button>
		<ng-template ngbNavContent>
			<div *ngFor="let dataStream of dataStreams">
				<h4 class="fs-5 text-primary" *ngIf="dataStreams && dataStreams.length > 1">{{ dataStream.supplier?.attributes?.['NAME'] }}</h4>
				<app-data-approvals [dataStreamId]="dataStream.id"></app-data-approvals>
			</div>
		</ng-template>
	</li>
</ul>

<div class="p-3 border rounded-3" [ngbNavOutlet]="nav"></div>
