import { Component, Input, OnInit } from '@angular/core';
import { IDataStreamAccess } from '@app/interfaces/idata-stream-access';
import { DataStreamService } from '@services/data-stream.service';
import { DialogService } from '@services/dialog.service';
import { EmployerService } from '@services/employer.service';

@Component({
  selector: 'app-data-approvals',
  templateUrl: './data-approvals.component.html',
  styleUrls: ['./data-approvals.component.scss']
})
export class DataApprovalsComponent implements OnInit {

  public accesses: IDataStreamAccess[] | null = [];
  public loading: boolean = false;

  @Input() dataStreamId: string | null = null;

  constructor(
    private dataStreamService: DataStreamService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (!this.dataStreamId) {
      return;
    }

    this.loading = true;
    this.dataStreamService.getAccesses(this.dataStreamId).then((accesses: IDataStreamAccess[] | null) => {
      this.accesses = accesses?.sort((a, b) => a.requestDate < b.requestDate ? -1 : a.requestDate > b.requestDate ? 1 : 0) || [];
      this.loading = false;
    });
  }

  public setAccess(access: IDataStreamAccess, status: string) {
    access.loading = true;

    this.dataStreamService.setDataStreamAccess(this.dataStreamId!, access.customerPartyId, status).then(() => {
      this.accesses = this.accesses?.map((a) => {
        if (a.customerPartyId === access.customerPartyId) {
          a.statusId = status;
          a.responseDate = new Date();
        }
        return a;
      }) || [];
      console.log(access);
      access.loading = false;
    }).catch(() => {
      access.loading = false;
      this.dialogService.alert(null, 'Leider ist etwas schief gelaufen.', 'danger');
    });
  }

}
