<h3 class="titlebar">Medien-Cockpit</h3>

<h4 class="fs-5">Artikeldaten herunterladen:</h4>
<p>Nachdem Sie die gewünschten Datenlieferanten ausgewählt und die AGBs/Nutzungsbestimmungen akzeptiert haben, wird der Download der Artikeldaten generiert. Die Download-Datei erhalten Sie kurz darauf per E-Mail, sie enthält Excel-Dateien der ausgewählten Datenlieferanten. Die dazugehörigen Artikelbilder sind per URL aufrufbar (s. Excel-Datei) oder über unseren FTP-Server abrufbar (Zugang siehe unten).</p>

<div class="row align-items-end">
	<div class="col-2 mt-4">
		<button (click)="selectAllDataStreams()" class="btn btn-block btn-sm btn-outline-secondary" *ngIf="!allDataStreamsChecked">
			<fa-icon icon="check-double"></fa-icon>
			Alle auswählen
		</button>

		<button (click)="unselectAllDataStreams()" class="btn btn-block btn-sm btn-outline-danger" *ngIf="allDataStreamsChecked">
			<fa-icon icon="check-double"></fa-icon>
			Alle abwählen
		</button>
	</div>

	<div class="col-3 mt-4">
		<button (click)="showRequestableDataStreams()" class="btn btn-block btn-sm btn-outline-primary" [disabled]="loadingRequestableDataStreams || !requestableDataStreams.length" *ngIf="loadingRequestableDataStreams || requestableDataStreams.length">
			<fa-icon icon="cloud"></fa-icon>
			Daten anfragen
			<fa-icon icon="spinner" animation="spin" *ngIf="loadingRequestableDataStreams"></fa-icon>
		</button>
	</div>

	<div class="col-2 text-center">
		<p class="mb-1">
			<small>Ansicht:</small>
		</p>

		<div class="btn-group">
			<button (click)="switchView('TILE')" class="btn btn-tile" [ngClass]="{ 'btn-secondary': viewSetting == 'TILE', 'btn-light': viewSetting != 'TILE' }">
				<fa-icon icon="th" size="lg"></fa-icon>
			</button>
			<button (click)="switchView('LIST')" class="btn btn-list" [ngClass]="{ 'btn-secondary': viewSetting == 'LIST', 'btn-light': viewSetting != 'LIST' }">
				<fa-icon icon="align-justify" size="lg"></fa-icon>
			</button>
		</div>
	</div>

	<div class="col-4 offset-1">
		<div class="input-group">
			<span class="input-group-text">
				<fa-icon icon="search"></fa-icon>
			</span>
			<input type="text" class="form-control" placeholder="GLN oder Name" [(ngModel)]="searchText" #searchbar />
			<button class="btn btn-light border" type="button" (click)="clearSearch()">
				<fa-icon icon="times-circle"></fa-icon>
			</button>
		</div>
	</div>
</div>

<div *ngIf="loadingDataStreams" class="text-center px-5 mt-5">
	<fa-icon icon="spinner" animation="spin" size="3x"></fa-icon>
</div>

<p class="alert alert-danger mt-3" *ngIf="!loadingDataStreams && !dataStreamGroupIds?.length">Sie sind derzeit für keine Sortimente freigeschaltet.</p>

<div class="mt-4" *ngIf="dataStreamGroupIds?.length">
	<div class="row mb-4">
		<div class="col-12">
			<div class="datastreams search-container" [ngClass]="{ tile: viewSetting == 'TILE', list: viewSetting == 'LIST' }">
				<form [formGroup]="downloadForm" (ngSubmit)="requestDownload()">
					<app-data-stream-group *ngFor="let dataStreamGroupId of dataStreamGroupIds" [dataStreamGroupId]="dataStreamGroupId" [viewSetting]="viewSetting" [parentFormGroup]="downloadForm" [select]="selectSubject.asObservable()" [searchText]="searchText" class="d-block mb-4"></app-data-stream-group>

					<div class="row align-items-center mb-3">
						<div class="col-auto">
							<label for="format" class="form-label mb-0">
								<strong>Datei-Format:</strong>
							</label>
						</div>
						<div class="col">
							<select class="form-select form-select-sm w-auto" formControlName="format" id="format">
								<option value="XLSX">Excel (XLSX)</option>
								<option value="CSV">CSV</option>
							</select>
						</div>
					</div>

					<div class="form-check">
						<input class="form-check-input" type="checkbox" id="agbCheck" formControlName="agreedAgb" />
						<label class="form-check-label" for="agbCheck"> Ich habe die AGBs/Nutzungsbestimmungen gelesen und stelle die datenschutzrechtliche Konformität sicher. </label>
					</div>

					<button type="submit" class="btn btn-secondary mt-3" [disabled]="!downloadForm.valid || loadingDataStreams">
						<fa-icon icon="download"></fa-icon>
						Download anfordern
					</button>
				</form>
			</div>
		</div>
	</div>
</div>

<ng-template #requestableDataStreamsModal let-modal>
	<div class="modal-header">
		<h5 class="modal-title">Artikeldaten anfragen</h5>
		<button type="button" class="btn-close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<p>Für die nachfolgenden Artikeldaten können Sie sich beim Datenlieferanten freischalten lassen. Wenn Sie eine Freigabe anfragen wird der Datenlieferant informiert und kann entscheiden, ob er der Anfrage zustimmmt. Sie werden über die Entscheidung per E-Mail informiert.</p>

		<div class="row" *ngIf="requestableDataStreams?.length">
			<app-request-data-stream *ngFor="let dataStream of requestableDataStreams" [dataStream]="dataStream" class="col-12 col-md-6 col-lg-4 mb-3 datastream"></app-request-data-stream>
		</div>
	</div>
</ng-template>
