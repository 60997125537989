import { Injectable } from '@angular/core';
import { Party } from '@models/party';
import { User } from '@models/user';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { PARTY_ATTRIBUTE_TYPES } from './constants.service';
import { ErrorService } from './error.service';
import { PartyService } from './party.service';

@Injectable({
  providedIn: 'root'
})
export class EmployerService {

  private currentEmployerParty$: BehaviorSubject<Party | null> = new BehaviorSubject<Party | null>(null);
  private currentUser: User | null = null;

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private partyService: PartyService
  ) {
    this.initCurrentEmployerParty();
  }

  private initCurrentEmployerParty() {
    this.authService.getCurrentUser$().subscribe((user: User | null) => {
      if (user?.username != this.currentUser?.username) {
        this.currentUser = user;
        if (user && user.partyId) {
          this.determineLastSelectedParty(user.partyId);
        } else {
          this.errorService.printError("No user or partyId set.");
        }
      }
    })
  }

  public getCurrentEmployerParty$(): BehaviorSubject<Party | null> {
    return this.currentEmployerParty$;
  }

  public getCurrentEmployerParty(): Promise<Party | null> {
    return new Promise<Party | null>((resolve, reject) => {
      let employerParty = this.currentEmployerParty$.getValue() as Party;

      return resolve(employerParty);
    });
  }

  public setCurrentEmployerParty(party: Party) {
    this.currentEmployerParty$.next(party);
  }

  public updateEmployerParty(sourcePartyId: string, employerPartyId: string): Promise<Party> {
    return new Promise((resolve, reject) => {
      return this.partyService.setAttribute(sourcePartyId, PARTY_ATTRIBUTE_TYPES.LAST_SELECTED_PARTYID, employerPartyId).then((party: Party) => {
        if (party && party.attributes) {
          return this.partyService.getParty(party.attributes[PARTY_ATTRIBUTE_TYPES.LAST_SELECTED_PARTYID]);
        }

        return Promise.reject(`Attribute value for '${PARTY_ATTRIBUTE_TYPES.LAST_SELECTED_PARTYID}' is not set.`);
      }).then((selectedEmployerParty: Party | null) => {
        if (selectedEmployerParty) {
          this.setCurrentEmployerParty(selectedEmployerParty);
          return resolve(selectedEmployerParty);
        }

        return reject(`Party  to select is not existing.`);
      }).catch((err) => {
        return reject(err);
      })
    })
  }

  private determineLastSelectedParty(userPartyId: string): Promise<void> {
    // set employerParty using the backend setting
    // return true if it is set, otherwise, return false
    return this.partyService.getAttribute(userPartyId, PARTY_ATTRIBUTE_TYPES.LAST_SELECTED_PARTYID).then((partyId: string | null) => {
      if (partyId != null) {
        return this.partyService.getParty(partyId);
      }

      return null;
    }).then((party: Party | null) => {
      if (party != null) {
        this.setCurrentEmployerParty(party);
      }

      return Promise.resolve();
    }).catch((err) => {
      this.errorService.printError(err);
      throw err;
    });
  }
}
