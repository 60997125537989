<form [formGroup]="userForm" novalidate (ngSubmit)="checkIfUserExists()">
    <div class="form-group">
      <label for="contactUser">{{ "Template.CreateUserFormComponent.label_user" | translate }}</label>
      <div class="row">
        <div class="col">
          <input type="text" class="form-control" id="firstname" formControlName="firstname"
            placeholder="{{ 'Template.CreateUserFormComponent.placeholder_firstName' | translate }}">
        </div>
        <div class="col">
          <input type="text" class="form-control" id="lastname" formControlName="lastname"
            placeholder="{{ 'Template.CreateUserFormComponent.placeholder_lastName' | translate }}">
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="em">{{ 'Template.CreateUserFormComponent.label_email' | translate }}</label>
      <input type="email" class="form-control" id="email" formControlName="email"
        placeholder="{{ 'Template.CreateUserFormComponent.placeholder_email' | translate }}">
    </div>

    <div *ngIf="error" class="alert alert-danger">
      {{ error }}
    </div>

    <button type="submit" class="btn btn-success d-block w-100" *ngIf="!userExists" [disabled]="!userForm.valid">
      {{ 'Template.CreateUserFormComponent.btn_create' | translate }}
      <fa-icon icon="chevron-right"></fa-icon>
      <fa-icon icon="spinner" [spin]="true" *ngIf="loadingCreate"></fa-icon>
    </button>

    <div *ngIf="userExists">
      <div class="alert alert-warning">
        {{ 'Template.CreateUserFormComponent.text_contactExisting' | translate }}
      </div>

      <button type="button" (click)="linkUser()" class="btn btn-success d-block w-100" [disabled]="!userForm.valid">
        {{ 'Template.CreateUserFormComponent.btn_proceed' | translate }}
        <fa-icon icon="chevron-right"></fa-icon>
        <fa-icon icon="spinner" [spin]="true" *ngIf="loadingCreate"></fa-icon>
      </button>
    </div>
  </form>
