<div class="titlebar">
	<h3>
		{{ "Template.CustomerDetailComponent.subHeader_customer" | translate }}
		<span *ngIf="customer?.attributes?.['NAME']">: {{ customer?.attributes?.['NAME'] }}</span>
	</h3>

	<div class="buttons">
		<button class="btn btn-secondary" [disabled]="loading['customer']" (click)="reload()">
			<fa-icon icon="arrow-rotate-right"></fa-icon>
		</button>
	</div>
</div>

<fa-icon icon="spinner" [spin]="true" *ngIf="loading['customer']"></fa-icon>

<div *ngIf="customer">
	<ul class="nav nav-tabs" ngbNav #tabs="ngbNav">
		<li class="nav-item" ngbNavItem>
			<a class="nav-link" ngbNavLink="#details">{{ "Template.CustomerDetailComponent.text_details" | translate }}</a>

			<ng-template ngbNavContent>
				<div class="tab-pane fade show" id="details" role="tabpanel">
					<h5 class="mb-2">{{ "Template.CustomerDetailComponent.subHeader_general" | translate }}:</h5>
					<div class="row">
						<div class="col-6 col-xs-12 px-4">
							<div class="row">
								<div class="col-6">
									<strong>{{ "Template.CustomerDetailComponent.text_customerNo" | translate }}:</strong>
								</div>
								<div class="col-6">
									{{ customer | identification : "CUSTOMER_NO" }}
								</div>
							</div>

							<div class="row">
								<div class="col-6">
									<strong>{{ "Template.CustomerDetailComponent.text_name" | translate }}:</strong>
								</div>
								<div class="col-6">
									{{ customer.attributes?.['NAME']}}
								</div>
							</div>

							<div class="row">
								<div class="col-6">
									<strong>{{ "Template.CustomerDetailComponent.text_active" | translate }}:</strong>
								</div>
								<div class="col-6">
									<span *ngIf="customer.enabled">{{ "Template.CustomerDetailComponent.text_yes" | translate }}</span>
									<strong class="text-danger" *ngIf="!customer.enabled">
										{{ "Template.CustomerDetailComponent.text_no" | translate }}
										<fa-icon icon="exclamation-triangle"></fa-icon>
									</strong>
								</div>
							</div>
						</div>
						<div class="col-6 col-xs-12">
							<button class="btn btn-secondary d-block w-100 mb-2" (click)="changeCustomerName()">
								{{ "Template.CustomerDetailComponent.btn_changeCompanyName" | translate }}
							</button>

							<button class="btn btn-warning d-block w-100" *ngIf="customer.enabled" (click)="toggleCustomer(false)">{{ "Template.CustomerDetailComponent.btn_disable" | translate }}</button>
							<button class="btn btn-success d-block w-100" *ngIf="!customer.enabled" (click)="toggleCustomer(true)">{{ "Template.CustomerDetailComponent.btn_enable" | translate }}</button>
						</div>
					</div>

					<hr />

					<!-- child component -->
					<app-employees *ngIf="customer" [isCustomer]="true" [party]="customer"></app-employees>
				</div>
			</ng-template>
		</li>
		<li class="nav-item" ngbNavItem>
			<a class="nav-link" ngbNavLink="#accesses">{{ "Template.CustomerDetailComponent.btn_editAccesses" | translate }}</a>
			<ng-template ngbNavContent>
				<div class="tab-pane fade show" id="accesses" role="tabpanel">
					<h5 class="mb-2">{{ "Template.CustomerDetailComponent.subHeader_accesses" | translate }}:</h5>
					<form [formGroup]="customerForm" novalidate>
						<div class="row" formArrayName="dataStreamGroups">
							<div class="col-12 ps-4">
								<div class="form-check" *ngFor="let dataStreamGroupControls of getFormArrayControls('dataStreamGroups'); let i = index" [formGroupName]="i">
									<input class="form-check-input" type="checkbox" [id]="'dsg_' + i" formControlName="enabled" />
									<label class="form-check-label" [for]="'dsg_' + i">
										{{ dataStreamGroupControls.value.name }}
									</label>
								</div>
							</div>

							<div class="col-12 col-md-6 col-lg-4 ps-4">
								<button class="btn btn-secondary btn-sm d-block w-100 my-1 px-5" *ngIf="!customerForm.get('editingDataStreamGroups')!.value" (click)="showForm({ controls: getFormArrayControls('dataStreamGroups') }, customer.dataStreamGroups, customerForm.get('editingDataStreamGroups'))">
									<fa-icon icon="pencil-alt" class="me-1"></fa-icon>
									<span>{{ "Template.CustomerDetailComponent.btn_editAccesses" | translate }}</span>
								</button>

								<!-- Edit Form Buttons -->
								<button class="btn btn-outline-danger btn-sm d-block w-100 mt-2 px-5" *ngIf="customerForm.get('editingDataStreamGroups')!.value" (click)="showForm({ controls: getFormArrayControls('dataStreamGroups') }, customer.dataStreamGroups, customerForm.get('editingDataStreamGroups'))">
									<fa-icon icon="circle-xmark" class="me-1"></fa-icon>
									<span>{{ "Common.btn_cancel" | translate }}</span>
								</button>
								<button class="btn btn-success btn-sm d-block w-100 mt-2" (click)="saveDataStreamGroupAccesses()" *ngIf="customerForm.get('editingDataStreamGroups')!.value">
									<fa-icon icon="check" class="me-1"></fa-icon>
									<span> {{ "Template.CustomerDetailComponent.btn_saveAccesses" | translate }}</span>
								</button>
							</div>
						</div>
					</form>

					<div *ngIf="customer.dataStreamGroups && customer.dataStreamGroups.length">
						<hr />
						<h5>{{ "Template.CustomerDetailComponent.subHeader_datastreamAccesses" | translate }}:</h5>

						<div *ngFor="let dataStreamGroup of customer.dataStreamGroups" class="mb-4 ps-2">
							<h6 class="mt-3">{{ dataStreamGroup.name }}</h6>
							<div class="ps-2">
								<app-data-stream-overview [dataStreamGroup]="dataStreamGroup" [employerPartyId]="customer.partyId"></app-data-stream-overview>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</li>
	</ul>

	<div class="tab-content" [ngbNavOutlet]="tabs"></div>
</div>
