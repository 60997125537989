import { Component, OnInit } from '@angular/core';
import { IDataCatalog } from '@app/interfaces/idata-catalog';
import { DataStream } from '@models/data-stream';
import { Party } from '@models/party';
import { DataCatalogService } from '@services/data-catalog.service';
import { DataStreamService } from '@services/data-stream.service';
import { EmployerService } from '@services/employer.service';

@Component({
  selector: 'app-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss']
})
export class CockpitComponent implements OnInit {

  public currentPartyId: string | null = null;

  public showDataManagement: boolean = false;
  public dataStreams: DataStream[] | null = [];
  public showOrderApproval: boolean = false;

  constructor(
    private employerService: EmployerService,
    private dataCatalogService: DataCatalogService,
    private dataStreamService: DataStreamService,
  ) { }

  ngOnInit(): void {
    this.employerService.getCurrentEmployerParty$().subscribe((currentParty: Party | null) => {
      this.showDataManagement = false;
      this.dataStreams = null;
      this.showOrderApproval = false

      if (!currentParty) {
        return;
      }

      this.currentPartyId = currentParty.partyId;

      this.dataCatalogService.getDataCatalogByParty(this.currentPartyId).then((dataCatalog: IDataCatalog | null) => {
        this.showOrderApproval = !!dataCatalog;
      });

      this.dataStreamService.getDataStreamsByParty(this.currentPartyId).then((dataStreams: DataStream[] | null) => {
        this.dataStreams = dataStreams;
      });
    });
  }

}
