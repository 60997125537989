import { Component, OnInit } from '@angular/core';
import { IDataCatalog } from '@app/interfaces/idata-catalog';
import { DataCatalogService } from '@services/data-catalog.service';
import { ErrorService } from '@services/error.service';
import { HttpService } from '@services/http.service';

@Component({
  selector: 'app-data-catalogs',
  templateUrl: './data-catalogs.component.html',
  styleUrls: ['./data-catalogs.component.scss']
})
export class DataCatalogsComponent implements OnInit {

  public dataCatalogs: IDataCatalog[] = [];

  constructor(
    private httpService: HttpService,
    private errorService: ErrorService,
    private dataCatalogService: DataCatalogService,
  ) { }

  ngOnInit(): void {
    this.dataCatalogService.getDataCatalogs().then((response: IDataCatalog[]) => {
      if (response) {
        this.dataCatalogs = response;
      } else {
        this.errorService.printError("No dataCatalogs found.");
      }
    })
  }

}
