import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { filter, timeout } from 'rxjs';

import { AuthService } from '@services/auth.service';
import { EmployerService } from '@services/employer.service';
import { PartyService } from '@services/party.service';

import { User } from '@models/user';
import { Party } from '@models/party';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {

  public error = "";
  public username: string = "";

  @ViewChild('changePasswordModal')
  templateRef!: TemplateRef<any>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private employerService: EmployerService,
    private partyService: PartyService,
    private modalService: NgbModal
  ) { }

  ngAfterViewInit(): void {
    this.checkUserPasswordChangeRequired();
  }

  ngOnInit(): void {
    console.log("ROTUE");
    this.routeByEmployerParty();
  }

  private routeByEmployerParty() {
    this.employerService.getCurrentEmployerParty$().pipe(filter(party => !!party)).pipe(timeout({ first: 4000 })).subscribe(async (employerParty: Party | null) => {
      let employerPartyId = employerParty?.partyId;

      let viewType = await this.partyService.getViewType(employerPartyId!);
      switch (viewType) {
        case 'CUSTOMER':
          this.router.navigate(['/app/customers/']);
          break;
        case 'SUPPLIER':
          this.router.navigate(['/app/suppliers/']);
          break;
        case 'ADMIN':
          this.router.navigate(['/app/admin/']);
          break;
        default:
          this.authService.logoutUser().then(() => {
            this.router.navigate(['/login/']);
          }).catch(() => {
            this.router.navigate(['/login/']);
          });
          break;
      }
    })
  }

  private checkUserPasswordChangeRequired() {
    console.log('Checking user password change required');
    let options: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    }

    this.authService.getCurrentUser$().subscribe((user: User | null) => {
      console.log('User: ', user);
      if (user != null && user.requirePasswordChange && user.username != this.username) {
        console.log(user.requirePasswordChange);
        // navigate or show modal
        this.username = user.username;
        if (!this.modalService.hasOpenModals()) {
          console.log('Opening modal');
          setTimeout(() => {
            console.log('Opening modal2');
            this.modalService.open(this.templateRef, options);
          })
        }
      }
    })
  }

  public exitPasswordForm(event: boolean) {
    this.modalService.dismissAll();
  }

}
