import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, firstValueFrom } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ErrorService } from '@services/error.service';

import { environment } from '@envs/environment';

export interface IProgressEvent {
  progress: number;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private stopReq: Subject<void> = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private errorService: ErrorService,
  ) { }

  public stopRequest() {
    this.stopReq.next();
  }

  public get(url: string, data?: any, options?: any): Promise<any | void> {
    let params = new HttpParams();

    if (data) {
      Object.keys(data).map((key) => {
        if (key && data[key] !== undefined) {
          params = params.append(key, data[key]);
        }
      });
    }

    return new Promise((resolve, reject) => {
      return firstValueFrom(this.httpClient.get(this.checkUrl(url), {
        headers: new HttpHeaders({
          'Accept-Language': 'en'
        }),
        params: params,
        withCredentials: true,
        observe: 'body',
        responseType: 'json',
      }).pipe(takeUntil(this.stopReq))).then((result) => {
        if (!environment.production) {
          console.log(url, result);
        }

        return resolve(result)
      }).catch((result) => {
        if (!environment.production) {
          console.log(url, result);
        }

        if (result && result.errorMessage) {
          return reject(result.errorMessage);
        }

        this.errorService.printError(result);


        if (options && options.fullResponse) {
          return reject(result);
        }

        return reject();
      });
    })
  }

  public post(url: string, data?: any, options?: any): Promise<any | void> {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders({});

      let postData: HttpParams | FormData | null = null;
      if (data) {
        if (data instanceof FormData) {
          postData = data;
        } else {
          let params = new HttpParams();

          Object.keys(data).map((key) => {
            if (key && data[key] !== undefined) {
              params = params.append(key, data[key]);
            }
          });

          postData = params;
        }
      }

      let response$ = this.httpClient.post(this.checkUrl(url), postData, {
        headers: headers,
        withCredentials: true,
        observe: 'body',
        responseType: 'json',
      });

      return firstValueFrom(response$).then((result: any) => {
        if (!environment.production) {
          console.log(url, result);
        }
        return resolve(result)
      }).catch((result: HttpErrorResponse) => {
        if (!environment.production) {
          console.log(url, result);
        }

        if (result && result.error && result.error.errorMessage) {
          return reject(result.error.errorMessage);
        }

        if (options && options.fullResponse) {
          return reject(result);
        }

        return reject();
      });
    })
  }

  private checkUrl(url: string): string {
    if (url && url.startsWith('**')) {
      url = url.replace('**', `${environment.apiUrl}/frontend`);

      var urlObj = new URL(url);
      urlObj.searchParams.append('lang', this.translateService.currentLang);

      url = urlObj.toString();
    }

    return url;
  }

  public openExternalUrl(url: string, params?: any) {
    url = this.checkUrl(url);

    if (params) {
      Object.keys(params).map((key) => {
        if (key && params[key] !== undefined) {
          url = url + `&${key}=${params[key]}`;
        }
      });
    }

    window.open(url, "_blank");
  }
}