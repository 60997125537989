import { Component, Input, OnInit } from '@angular/core';

import { DataStreamService } from '@services/data-stream.service';
import { DialogService } from '@services/dialog.service';

import { DataStream } from '@models/data-stream';
import { DataStreamGroup } from '@models/data-stream-group';
import { IDataStreamAccess } from '@app/interfaces/idata-stream-access';

@Component({
  selector: 'app-data-stream-overview',
  templateUrl: './data-stream-overview.component.html',
  styleUrls: ['./data-stream-overview.component.scss']
})
export class DataStreamOverviewComponent implements OnInit {

  @Input() dataStreamGroup!: DataStreamGroup;
  @Input() employerPartyId!: string;

  public accesses: IDataStreamAccess[] | null = [];
  public dataStreams: DataStream[] = [];
  public loading: boolean = true;

  constructor(
    private dataStreamService: DataStreamService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.dataStreamService.getDataStreamsForDataStreamGroup(this.dataStreamGroup.id, this.employerPartyId).then((dataStreams: DataStream[]) => {
      this.dataStreams = dataStreams;
      this.loading = false;
    }).catch(() => {
      this.dialogService.alert(null, 'Leider ist etwas schief gelaufen.', 'danger');
      this.loading = false;
    });
  }

}
