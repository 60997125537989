import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, first, from, Observable, Subject, Subscription, switchMap } from 'rxjs';

import { DataStreamService } from '@services/data-stream.service';
import { EmployerService } from '@services/employer.service';

import { DataStreamGroup } from '@models/data-stream-group';
import { DataStream } from '@models/data-stream';
import { Party } from '@models/party';

@Component({
  selector: 'app-data-stream-group',
  templateUrl: './data-stream-group.component.html',
  styleUrls: ['./data-stream-group.component.scss']
})
export class DataStreamGroupComponent implements OnInit, OnDestroy {

  @Input() dataStreamGroupId!: string;
  @Input() viewSetting: string = 'TILE';
  @Input() parentFormGroup!: FormGroup;
  @Input() searchText: string = '';

  @Input() select: Observable<boolean> = new Observable<boolean>();
  private selectSubscription!: Subscription;
  public selectSubject: Subject<boolean> = new Subject<boolean>();

  public loading: boolean = true;
  public dataStreamGroup!: DataStreamGroup;
  public dataStreams: DataStream[] = [];

  constructor(
    private employerService: EmployerService,
    private dataStreamService: DataStreamService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    let employerParty: Party | null = null;

    this.employerService.getCurrentEmployerParty$().pipe(filter(party => !!party)).pipe(switchMap((pEmployerParty) => {
      employerParty = pEmployerParty;

      return from(this.dataStreamService.getDataStreamGroup(this.dataStreamGroupId)).pipe(first());
    })).pipe(switchMap((dataStreamGroup: DataStreamGroup) => {
      this.dataStreamGroup = dataStreamGroup;

      return from(this.dataStreamService.getDataStreamsForDataStreamGroup(this.dataStreamGroupId, employerParty?.partyId!, "APPROVED")).pipe(first());
    })).subscribe((dataStreams: DataStream[]) => {
      this.dataStreams = this.sortDataStreams(dataStreams);
      this.loading = false;
    });

    this.selectSubscription = this.select.subscribe((select: boolean) => {
      this.selectSubject.next(select);
    });
  }

  ngOnDestroy() {
    this.selectSubscription.unsubscribe();
  }

  private sortDataStreams(dataStreams: DataStream[]): DataStream[] {
    return dataStreams.sort((a: DataStream, b: DataStream) => {
      const aName = a.supplier?.attributes?.["NAME"] || '';
      const bName = b.supplier?.attributes?.["NAME"] || '';

      return aName < bName ? -1 : aName > bName ? 1 : 0
    });
  }
}
