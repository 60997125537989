import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from '@models/customer';
import { Party } from '@models/party';
import { Supplier } from '@models/supplier';

@Pipe({
  name: 'identification'
})
export class IdentificationPipe implements PipeTransform {

  transform(party: Customer | Party | Supplier, ...args: unknown[]): string | null {
    const identifications = party?.identifications || [] as any[];
    const identificationType = args[0] as string;

    const identification = identifications.find((i) => i.type == identificationType);
    if (identification) {
      return identification.value;
    }

    return null;
  }

}
