<div class="row" *ngIf="ftpInfo && currentUser">
	<div class="col-6 col-xs-12">
		<div class="border border-opacity-50 border-secondary mt-3 p-3 rounded">
			<h5 class="text-primary">{{ "Template.MainComponent.label_ftpCredentials" | translate }}:</h5>
			<table class="table table-bordered mt-1">
				<tbody>
					<tr>
						<td>{{ "Template.MainComponent.label_protocol" | translate }}:</td>
						<td><strong>SFTP</strong></td>
					</tr>

					<tr class="align-middle">
						<td>{{ "Template.MainComponent.label_host" | translate }}:</td>
						<td>
							<strong class="d-inline-block py-1">{{ ftpInfo.host }}</strong>

							<button type="button" class="btn btn-outline-primary btn-sm float-end" tabindex="0" data-toggle="tooltip" (click)="copyToClipBoard(ftpInfo.host)" ngbTooltip="{{ 'Common.copyToClipboard' | translate }}">
								<fa-icon icon="clipboard" class="far"></fa-icon>
							</button>
						</td>
					</tr>
					<tr class="align-middle">
						<td>{{ "Template.MainComponent.label_port" | translate }}:</td>
						<td>
							<strong class="d-inline-block py-1">{{ ftpInfo.port }}</strong>

							<button type="button" class="btn btn-outline-primary btn-sm float-end" tabindex="0" data-toggle="tooltip" (click)="copyToClipBoard(ftpInfo.port)" ngbTooltip="{{ 'Common.copyToClipboard' | translate }}">
								<fa-icon icon="clipboard" class="far"></fa-icon>
							</button>
						</td>
					</tr>
					<tr class="align-middle">
						<td>{{ "Template.MainComponent.label_username" | translate }}:</td>
						<td>
							<strong class="d-inline-block py-1">{{ currentUser.email! }}</strong>

							<button type="button" class="btn btn-outline-primary btn-sm float-end" tabindex="0" data-toggle="tooltip" (click)="copyToClipBoard(currentUser.email!)" ngbTooltip="{{ 'Common.copyToClipboard' | translate }}">
								<fa-icon icon="clipboard" class="far"></fa-icon>
							</button>
						</td>
					</tr>
					<tr class="align-middle">
						<td>{{ "Template.MainComponent.label_password" | translate }}:</td>
						<td>
							<em>{{ "Template.MainComponent.label_personalPassword" | translate }}</em>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
