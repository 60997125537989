import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DataCatalogService {

  constructor(
    private httpService: HttpService,
    private errorService: ErrorService,
  ) { }

  public getDataCatalogs(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-catalogs/getAll').then((dataCatalogs: any[]) => {
        return resolve(dataCatalogs);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }

  public getDataCatalogByParty(employerPartyId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpService.get('**/data-catalogs/getDataCatalogByParty', {
        partyId: employerPartyId,
      }).then((dataCatalog: any) => {
        return resolve(dataCatalog);
      }).catch((err) => {
        this.errorService.printError(err);
        return reject();
      })
    })
  }
}
