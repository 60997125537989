import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { AdminService } from '@services/admin.service';
import { DialogService } from '@services/dialog.service';
import { DataStreamGroup } from '@models/data-stream-group';
import { DataStreamService } from '@services/data-stream.service';
import { TranslateService } from '@ngx-translate/core';
import { PartyService } from '@services/party.service';
import { CustomerService } from '@services/customer.service';

import { Customer } from '@models/customer';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {

  private id: string | null = null;
  public customer: Customer | null = null;
  public loading: any = {
    'customer': true,
  };
  public customerForm: FormGroup;
  public defaultFormValues: any = {};

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private viewContainerRef: ViewContainerRef,
    private adminService: AdminService,
    private customerService: CustomerService,
    private partyService: PartyService,
    private dataStreamService: DataStreamService,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) {
    this.customerForm = this.formBuilder.group({
      dataStreamGroups: new FormArray([]),
      editingDataStreamGroups: [false],
    });
  }

  ngOnInit(): void {
    this.reload();
  }

  public reload() {
    this.loadCustomer().then((customer: Customer) => {
      this.customer = customer;
    })
  }

  private loadCustomer(): Promise<Customer> {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loading['customer'] = true;
    let retCustomer: Customer;

    return new Promise((resolve, reject) => {
      this.customerService.getCustomer(this.id).then((customer: Customer) => {
        retCustomer = customer;
        this.defaultFormValues = customer;
        this.loading['customer'] = false;

        this.customerForm = this.formBuilder.group({
          dataStreamGroups: new FormArray([]),
          editingDataStreamGroups: [false],
        });

        return this.dataStreamService.getDataStreamGroups();
      }).then((dataStreamGroups: DataStreamGroup[]) => {
        if (dataStreamGroups && dataStreamGroups.length) {
          dataStreamGroups.forEach(async (dataStreamGroup: DataStreamGroup) => {
            let enabled = retCustomer?.dataStreamGroups?.filter((customerDataStreamGroup: DataStreamGroup) => customerDataStreamGroup?.id == dataStreamGroup?.id).length;

            (this.customerForm.controls['dataStreamGroups'] as FormArray).push(this.formBuilder.group({
              id: [dataStreamGroup.id],
              name: [dataStreamGroup.name],
              enabled: [{
                value: !!enabled,
                disabled: true,
              }],
              isCollapsed: [true],
            }))
          });
        }

        return resolve(retCustomer);
      }).catch(() => {
        this.loading['customer'] = false;
        this.dialogService.alert(null, this.translateService.instant("Dialog.CustomerDetailComponent.msg_customerNotLoaded"), 'danger', 0, false);
        return reject();
      });
    })

  }

  public async toggleCustomer(enable: boolean) {
    let title: string;
    let body: string;
    let messageSuccess: string;
    let messageFailure: string;
    if (enable) {
      title = this.translateService.instant("Dialog.CustomerDetailComponent.text_enableCustomer");
      body = this.translateService.instant("Dialog.CustomerDetailComponent.text_confirmEnableCustomer");
      messageSuccess = this.translateService.instant("Dialog.CustomerDetailComponent.msg_customerEnabled");
      messageFailure = this.translateService.instant("Dialog.CustomerDetailComponent.msg_customerNotEnabled");
    } else {
      title = this.translateService.instant("Dialog.CustomerDetailComponent.text_disableCustomer");
      body = this.translateService.instant("Dialog.CustomerDetailComponent.text_confirmDisableCustomer");
      messageSuccess = this.translateService.instant("Dialog.CustomerDetailComponent.msg_customerDisabled");
      messageFailure = this.translateService.instant("Dialog.CustomerDetailComponent.msg_customerNotEnabled");
    }

    if (! await this.dialogService.confirm(
      title,
      body,
      this.viewContainerRef
    )) {
      return;
    }

    this.dialogService.showLoader('customer-detail.toggleCustomer');

    this.adminService.updatePartyEnabled(enable, this.customer?.partyId).then(() => {
      this.dialogService.alert(null, messageSuccess, 'success');
      this.reload();

      this.dialogService.dismissLoader('customer-detail.toggleCustomer');
    }).catch((error: string) => {
      this.dialogService.alert(null, error || messageFailure, 'danger');

      this.dialogService.dismissLoader('customer-detail.toggleCustomer');
    });
  }

  public changeCustomerName() {
    this.dialogService.prompt(
      this.translateService.instant("Dialog.CustomerDetailComponent.text_editCompanyName"),
      this.translateService.instant("Dialog.CustomerDetailComponent.text_enterNewCompanyName"),
      'text',
      this.customer?.attributes?.['NAME'] || '',
      this.viewContainerRef,
      this.translateService.instant("Dialog.CustomerDetailComponent.text_companyName")
    ).then((input: string | number | null) => {
      if (typeof input == 'string') {
        this.dialogService.showLoader('customer-detail.changeCustomerName');

        return this.partyService.updatePartyName(input, this.customer?.partyId);
      }

      return Promise.reject();
    }).then(() => {
      this.dialogService.alert(
        null,
        this.translateService.instant("Dialog.CustomerDetailComponent.text_companyNameEditedSuccessfully"),
        'success'
      );

      this.reload();
      this.dialogService.dismissLoader('customer-detail.changeCustomerName');
    }).catch(() => {
      this.dialogService.alert(
        null,
        this.translateService.instant("Dialog.CustomerDetailComponent.text_companyNameNotEditedSuccessfully"),
        'danger'
      );

      this.dialogService.dismissLoader('customer-detail.changeCustomerName');
    });
  }

  public saveDataStreamGroupAccesses() {
    let dataStreamGroupIds = [] as string[];

    this.dialogService.showLoader('customer-detail.saveDataStreamGroupAccesses');

    this.customerForm.controls['dataStreamGroups'].value.forEach((control: any) => {
      if (control.enabled) {
        dataStreamGroupIds.push(control.id);
      }
    });

    this.dataStreamService.setDataStreamGroupAccesses(this.customer?.partyId, dataStreamGroupIds).then(() => {
      this.dialogService.alert(null, this.translateService.instant("Dialog.CustomerDetailComponent.msg_accessesSaved"), 'success');

      this.showForm({ controls: this.getFormArrayControls('dataStreamGroups') }, this.customer!.dataStreamGroups, this.customerForm.get('editingDataStreamGroups'));
      this.dialogService.dismissLoader('customer-detail.saveDataStreamGroupAccesses');
    }).catch((error: string) => {
      this.dialogService.alert(null, error || this.translateService.instant("Dialog.CustomerDetailComponent.msg_accessesNotSaved"), 'danger');
      this.dialogService.dismissLoader('customer-detail.saveDataStreamGroupAccesses');
    })
  }

  public showForm(form: any, defaultValues: any, editingControl: any) {
    if (editingControl) {
      if (editingControl.value) {
        editingControl.patchValue(false);

        Object.keys(form.controls).forEach((fieldName: string) => {
          form.controls[fieldName].patchValue(this.defaultFormValues[fieldName] || defaultValues[fieldName] || '');
          form.controls[fieldName].disable();
        });
      } else {
        editingControl.patchValue(true);

        Object.keys(form.controls).forEach((fieldName: string) => {
          form.controls[fieldName].enable();
        });
      }
    }
  }

  public getFormArrayControls(arrayName: string): FormGroup[] {
    return (this.customerForm.controls[arrayName] as FormArray).controls as FormGroup[];
  }


}
